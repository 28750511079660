import { Controller } from "stimulus"
import { showTarget, hideTarget } from '../../helpers/visibility'

export default class extends Controller {
  static targets = [
    "allBrands",
    "brandContainer",
    "controller",
    "eventType",
    "eventTypeWrapper",
    "timeNumberWrapper",
    "timeUnitWrapper",
    "beforeOrAfter",
    "senderWrapper",
    "subjectWrapper",
    "messageWrapper",
    "smsBodyWrapper"
  ]

  connect() {
    this.dependencies = JSON.parse(this.controllerTarget.getAttribute("data-dependencies"))

    this.updateVisibility()
    this.setVisibleContentFields()
  }

  updateVisibility() {
    this.setVisibleUnitsOfTime()
    this.setVisibleContentFields()
    this.setBrands()
  }


  setVisibleUnitsOfTime() {
    switch(this.beforeOrAfterTarget.value) {
      case "manual":
        this.eventTypeWrapperTarget.classList.remove("col-sm-2")
        this.eventTypeWrapperTarget.classList.add("col-sm-4")
        this.timeUnitWrapperTarget.classList.add("d-none")
        this.timeNumberWrapperTarget.classList.add("d-none")
        break
      case "when":
        this.eventTypeWrapperTarget.classList.remove("col-sm-2")
        this.eventTypeWrapperTarget.classList.add("col-sm-4")
        this.timeUnitWrapperTarget.classList.add("d-none")
        this.timeNumberWrapperTarget.classList.add("d-none")
        break
      default:
        this.eventTypeWrapperTarget.classList.remove("col-sm-4")
        this.eventTypeWrapperTarget.classList.add("col-sm-2")
        this.timeUnitWrapperTarget.classList.remove("d-none")
        this.timeNumberWrapperTarget.classList.remove("d-none")
        break
    }
  }

  setVisibleContentFields() {
    switch(this.eventTypeTarget.value) {
      case "send_email":
        this.senderWrapperTarget.classList.remove('d-none')
        this.subjectWrapperTarget.classList.remove('d-none')
        this.messageWrapperTarget.classList.remove('d-none')
        this.smsBodyWrapperTarget.classList.add('d-none')
        break
      case "send_sms":
        this.senderWrapperTarget.classList.add('d-none')
        this.subjectWrapperTarget.classList.add('d-none')
        this.messageWrapperTarget.classList.add('d-none')
        this.smsBodyWrapperTarget.classList.remove('d-none')
        break
    }
  }

  setBrands() {
    if(this.hasAllBrandsTarget && this.hasBrandContainerTarget) {
      if(this.allBrandsTarget.checked) {
        hideTarget(this.brandContainerTarget)
      }
      else {
        showTarget(this.brandContainerTarget)
      }
    }
  }
}
