import { Controller } from "stimulus"
import { showTarget, hideTarget } from '../../helpers/visibility'

export default class extends Controller {
  static targets = [
    "pullEvents",
    "blockAvailability",
    "blockAvailabilityWrapper",
    "enforceBuffer",
    "enforceBufferWrapper"
  ]

  connect() {
    this.updateVisibility()
  }

  updateVisibility() {
    if(this.pullEventsTarget.checked) {
      showTarget(this.blockAvailabilityWrapperTarget)

      if(this.blockAvailabilityTarget.checked) [
        showTarget(this.enforceBufferWrapperTarget)
      ]
      else {
        hideTarget(this.enforceBufferWrapperTarget)
      }
    }
    else {
      hideTarget(this.blockAvailabilityWrapperTarget)
      hideTarget(this.enforceBufferWrapperTarget)
    }
  }
}
